import { Button } from "@progress/kendo-react-buttons";
import type { MenuSelectEvent } from "@progress/kendo-react-layout";
import cls from "classnames";
import NProgress from "nprogress";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchUsersByRoleNameAction } from "../../../libs/authentication/data-access/src/lib/authentication.slice";
import { DEFAULT_PAGE_SIZE } from "../../../libs/common/models/src/lib/constants/grid.constants";
import { JOB_STATUS_MAPPINGS } from "../../../libs/common/models/src/lib/constants/job.constants";
import { NOT_AVAILABLE } from "../../../libs/common/models/src/lib/constants/messages.constants";
import {
	ADMIN_ROLE,
	OPERATOR_ROLE,
} from "../../../libs/common/models/src/lib/constants/user.constants";
import { CelerumActions } from "../../../libs/common/models/src/lib/enums/actions.enum";
import {
	ModalSize,
	ModalType,
} from "../../../libs/common/models/src/lib/enums/modal.enums";
import { ParentType } from "../../../libs/common/models/src/lib/enums/parent-type.enum";
import type { IJobDetails } from "../../../libs/common/models/src/lib/interfaces/job.interface";
import {
	useAppDispatch,
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../libs/common/stores/src/lib/utils";
import { CelerumMoreDetailsButtonGrid } from "../../../libs/common/ui/src/lib/components/celerum-buttons/celerum-buttons.component";
import { CelerumConfirmModal } from "../../../libs/common/ui/src/lib/components/celerum-confirm-modal/celerum-confirm-modal.component";
import { CelerumDetailsHeader } from "../../../libs/common/ui/src/lib/components/celerum-details-header/celerum-details-header.component";
import { CelerumLoader } from "../../../libs/common/ui/src/lib/components/celerum-loader/celerum-loader.components";
import { CelerumModal } from "../../../libs/common/ui/src/lib/components/celerum-modal/celerum-modal.component";
import { handleOptions } from "../../../libs/common/utils/src/lib/helpers/action.helpers";
import { generateCreatedByText } from "../../../libs/common/utils/src/lib/helpers/date.helpers";
import { fetchGoodsByJobIdAction } from "../../../libs/goods/data-access/src/lib/goods.slice";
import { GoodsInformationFeature } from "../../../libs/goods/feature/src/lib/goods-information-feature/goods-information-feature";
import {
	clearJobAction,
	fetchJobByIdAction,
} from "../../../libs/jobs/data-access/src/lib/jobs.slice";
import { DuplicateJobForm } from "../../../libs/jobs/feature/src/lib/components/duplicate-job-form/duplicate-job-form.component";
import {
	canCancel,
	canDelete,
	canDuplicate,
	canForceComplete,
	canGenerateFileFront,
	canPause,
	canResume,
	canSendToInvoice,
} from "../../../libs/jobs/feature/src/lib/helpers/job-option.helpers";
import { useJobActionSelected } from "../../../libs/jobs/feature/src/lib/hooks/use-job-action-selected.hook";
import { JobInformationFeature } from "../../../libs/jobs/feature/src/lib/job-information-feature/job-information-feature";
import { LegsInformationFeature } from "../../../libs/legs/feature/src/lib/legs-information-feature/legs-information-feature";
import { fetchTrucksAction } from "../../../libs/trucks/data-access/src/lib/trucks.slice";
import { DFlex } from "../../../new/display/DFlex";
import { jobApi } from "../../../new/helpers";
import styles from "./job-details.module.css";

export const JobDetailsPage = () => {
	const dispatch = useAppDispatch();
	const dispatchWithNotifications = useAppDispatchWithNotifications();
	const navigate = useNavigate();
	const handleSelectedAction = useJobActionSelected();

	const { id } = useParams();

	const { job, loading } = useAppSelector((state) => state.jobs);
	const { navbarExpanded } = useAppSelector((state) => state.userInterface);

	const [showModal, setShowModal] = useState<{
		duplicate: boolean;
		actions: boolean;
	}>({
		duplicate: false,
		actions: false,
	});
	const [selectedAction, setSelectedAction] = useState<CelerumActions | null>(
		null,
	);
	const [modalSize, setModalSize] = useState<ModalSize>(ModalSize.Large);

	const renderedJobDetails = useMemo(() => {
		if (job) {
			return {
				...job,
				startDate: new Date(job.startDate),
				endDate: new Date(job.endDate),
			};
		}
	}, [job]);

	const isJobInvoiced = job?.actions?.isInvoiced === true;

	const renderedHeaderSubtitle = useMemo(() => {
		if (job) {
			return generateCreatedByText(
				job.createdByUser?.fullName ?? NOT_AVAILABLE,
				job.creationTime,
			);
		}
	}, [job]);

	const handleMoreOptions = {
		canDelete,
		canCancel,
		canPause,
		canDuplicate,
		canForceComplete,
		canGenerateFileFront,
		canSendToInvoice,
		canResume,
	};

	const handleMoreOptionsSelected = (event: MenuSelectEvent) => {
		const value = event.item.text as keyof typeof CelerumActions;
		if (!value) return;
		if (CelerumActions[value] === CelerumActions.Duplicate) {
			setShowModal({ ...showModal, duplicate: true });
		} else {
			setSelectedAction(CelerumActions[value]);
			setShowModal({ ...showModal, actions: true });
		}
	};

	const handleSubmit = async () => {
		if (!selectedAction || !renderedJobDetails) return;
		if (
			selectedAction === CelerumActions.Cancel ||
			selectedAction === CelerumActions.Delete
		) {
			await jobApi.job.jobChangeStatusCancelCreate(renderedJobDetails.id);
			closeModal();
			navigate("/jobs2");
		} else {
			handleSelectedAction(
				selectedAction,
				(renderedJobDetails as IJobDetails).id,
				(renderedJobDetails as IJobDetails).uniqueId,
			);
		}
	};

	const closeModal = () => {
		setShowModal({ duplicate: false, actions: false });
	};

	useEffect(() => {
		if (id && !Number.isNaN(Number.parseInt(id))) {
			dispatchWithNotifications({
				action: fetchJobByIdAction,
				payload: Number(id),
				errorMessage: "Could not fetch job.",
			});
			dispatchWithNotifications({
				action: fetchGoodsByJobIdAction,
				payload: Number(id),
				errorMessage: "Could not fetch goods.",
			});
		}
	}, [id, dispatchWithNotifications]);

	useEffect(() => {
		if (loading.status) {
			NProgress.start();
		} else {
			NProgress.done();
		}
	}, [loading.status]);

	useEffect(() => {
		dispatchWithNotifications({
			action: fetchUsersByRoleNameAction,
			payload: [OPERATOR_ROLE, ADMIN_ROLE],
			errorMessage: "Could not fetch operators!",
		});

		dispatchWithNotifications({
			action: fetchTrucksAction,
			payload: { page: 1, pageSize: DEFAULT_PAGE_SIZE },
			errorMessage: "Could not fetch trucks.",
		});

		return () => {
			dispatch(clearJobAction());
		};
	}, [dispatch, dispatchWithNotifications]);

	return (
		<div
			className={navbarExpanded ? styles.containerOpen : styles.containerClose}
		>
			<title>
				Job {id} - {job?.uniqueId}
			</title>
			<CelerumDetailsHeader
				loading={loading.job}
				title={job?.uniqueId ?? "New Job"}
				subtitle={renderedHeaderSubtitle}
				status={JOB_STATUS_MAPPINGS[job?.status ?? 1]}
			>
				<DFlex>
					<Button
						themeColor="tertiary"
						fillMode="outline"
						onClick={() => {
							navigate(`/jobs2/${id}`);
						}}
					>
						✨ Try new view!
					</Button>

					{job && !isJobInvoiced && (
						<CelerumMoreDetailsButtonGrid
							items={handleOptions(job, handleMoreOptions)}
							handleOnSelect={handleMoreOptionsSelected}
							text={"Actions"}
						/>
					)}
				</DFlex>
			</CelerumDetailsHeader>
			<div
				className={
					navbarExpanded
						? cls(styles.cardsContainer, styles.cardsContainer__open)
						: cls(styles.cardsContainer, styles.cardsContainer__close)
				}
			>
				<JobInformationFeature
					className={styles.jobInfoContainer}
					isJobInvoiced={isJobInvoiced}
				/>
				<GoodsInformationFeature
					className={styles.goodsContainer}
					isJobInvoiced={isJobInvoiced}
					parentType={ParentType.Job}
					parentId={id}
				/>
				<LegsInformationFeature
					className={styles.legsContainer}
					isJobInvoiced={false}
					parentType={ParentType.Job}
					parentId={id}
				/>
			</div>
			{renderedJobDetails && (
				<CelerumModal
					title="Job Details"
					width={modalSize}
					toggleDialog={closeModal}
					visible={showModal.duplicate}
				>
					{loading.job ? (
						<div className={styles.verticalWrapper}>
							<CelerumLoader visible />
						</div>
					) : (
						<DuplicateJobForm
							formState={renderedJobDetails}
							onClose={closeModal}
							setModalSize={setModalSize}
						/>
					)}
				</CelerumModal>
			)}
			<CelerumConfirmModal
				title={
					selectedAction &&
					`Are you sure you want to ${CelerumActions[selectedAction]
						?.toString()
						.toLowerCase()} this job?`
				}
				entityName="job"
				entityProperty="with ID"
				entityValue={(renderedJobDetails as IJobDetails)?.id?.toString()}
				isOpen={showModal.actions}
				subtitle={
					selectedAction === CelerumActions.Delete
						? "The job cannot be recovered."
						: "This action can be reverted afterwards."
				}
				type={
					selectedAction === CelerumActions.Delete
						? ModalType.Delete
						: ModalType.Warning
				}
				handleSubmit={handleSubmit}
				handleClose={closeModal}
			/>
		</div>
	);
};
